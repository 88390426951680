"use client";

import styled from "styled-components";
import { includeSpacing } from "@boxt/design-system";

export const Icon = styled.div`
  width: 100%;
  height: 100%;
  max-width: 124px;
  margin: 0 auto;
  ${includeSpacing({ mb: 1 })};
`;
