import type { Flows } from "@Collections/photoFlows";
import { Products } from "@Collections/products";

const getI18nNamespace = ({ flow, productType }: { flow: Flows; productType: Products | null }) => {
  if (productType === null) {
    return {
      photos: `photos/${flow}/photos`,
      uploadPhotos: `photos/${flow}/upload-photos`,
    };
  }

  return (
    {
      [Products.HeatPump]: {
        photos: `photos/${flow}/heat-pumps/photos`,
        uploadPhotos: `photos/${flow}/heat-pumps/upload-photos`,
      },
      [Products.BatteryOnly]: {
        photos: `photos/${flow}/battery-only/photos`,
        uploadPhotos: `photos/${flow}/battery-only/upload-photos`,
      },
      [Products.Solar]: {
        photos: `photos/solar/photos`,
        uploadPhotos: `photos/solar/upload-photos`,
      },
    }[productType] ?? {
      photos: `photos/${flow}/photos`,
      uploadPhotos: `photos/${flow}/upload-photos`,
    }
  );
};

export default getI18nNamespace;
