import type { PhotoStatus } from "@DataAccess/local/customers/orders/photos";
import { PhotoUploadStatus } from "@Components/pages/photos/PhotoUpload/constants";
import { ReactComponent as CrossSVG } from "@Images/icons/cross.svg";
import { ReactComponent as PhotoSVG } from "@Images/icons/photo-upload.svg";
import { ReactComponent as TickSVG } from "@Images/screening-icons/yes.svg";

const getPhotoIcon = (status: PhotoStatus) => {
  switch (status) {
    case PhotoUploadStatus.Uploaded:
      return TickSVG;
    case PhotoUploadStatus.Rejected:
      return CrossSVG;

    default:
      return PhotoSVG;
  }
};

export { getPhotoIcon };
