"use client";

import styled from "styled-components";
import { colors, includeSpacing, rem } from "@boxt/design-system";

export const Container = styled.div`
  ${includeSpacing({ mb: 3, mt: { lg: 2, md: 2, sm: 4 } })};
  background-color: ${colors.white};
  border-radius: 6px;
`;

export const PhotoHeader = styled.div`
  ${includeSpacing({ mb: 3 })};
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.platinum.light};
  padding-bottom: 1.25rem;
`;

export const Span = styled.span`
  margin-left: 0.5rem;
  line-height: 1.5rem;
  font-size: ${rem(16)};
  font-weight: 400;
`;
