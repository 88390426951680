"use client";

import styled from "styled-components";
import { breakpoint, includeSpacing, rem } from "@boxt/design-system";

import { ReactComponent as PhotosDone } from "@Images/phone.svg";

export const Icon = styled.div`
  width: 100%;
  height: 100%;
  ${includeSpacing({ mb: 1 })};
  svg {
    margin: 0 auto;
    display: block;
  }
`;

export const PhotosDoneSVG = styled(PhotosDone)`
  max-width: ${rem(200)};
  margin: 0 auto;

  ${breakpoint("md")} {
    max-width: ${rem(300)};
  }

  ${breakpoint("lg")} {
    max-width: 100%;
    margin: 0;
  }
`;

export const PhotosDoneVerticalSVG = styled(PhotosDone)`
  display: block;
  max-width: ${rem(230)};
  margin: 0 auto;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const OrdersRow = styled.div`
  ${breakpoint("lg")} {
    min-height: ${rem(500)};
  }
`;
